/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Add zero before number
  function minTwoDigits(n) {
    return (n < 10 ? '0' : '') + n;
  }

  var heroBgLoad = function() {
    // Show BG once loaded to prevent flicker
    var hero = $('.hero-bg .bg');

    if (hero.length) {
      var bg = hero.css('backgroundImage'),
        src = bg.replace(/(^url\()|(\)$|[\"\'])/g, '');

      $('<img/>')
        .attr('src', src)
        .on('load', function() {
          hero.addClass('loaded');
        });
    }
  };

  // AOS - Scroll Animations
  var aos = function() {
    AOS.init({
      duration: 800,
      disable: function() {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(
          navigator.userAgent
        );
      }
    });
  };

  var copyToClipboard = function () {
    if(navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      $('.js-copy-click').on('click', function () {
        var el = document.getElementById('js-code-copy');
        iosCopyToClipboard(el);
      });
    } else {
      $('.js-copy-click').on('click', function () {
        $("#js-code-copy").select();
        document.execCommand('copy');
      });
    }
  }

  var iosCopyToClipboard = function(el) {
    var oldContentEditable = el.contentEditable,
      oldReadOnly = el.readOnly,
      range = document.createRange();

    el.contentEditable = true;
    el.readOnly = false;
    range.selectNodeContents(el);

    var s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999);
    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;
    document.execCommand('copy');
  };
  // -------------------

  // Count Up
  var countUp = function() {
    var countUp = $('.js-count-up');

    if (countUp.length) {
      var waypoints = $('.number-satisfaction').waypoint(
        function(direction) {
          $.each(countUp, function() {
            var options = {
              useEasing: false
            };

            var count = $(this).data('count'),
              numAnim = new CountUp(this, 0, count, 0, 2.5, options);

            numAnim.start();

            waypoints[0].destroy(); //stop
          });

          $('.number-satisfaction li').each(function() {
            var circle = $(this).find('.circle'),
              data = circle.prev('.number').find('.js-count-up');

            // IE bugs out if over 6 strokewidth
            if (
              Object.hasOwnProperty.call(window, 'ActiveXObject') &&
              !window.ActiveXObject
            ) {
              var stroke = 6,
                trial = 6;
              $(this).addClass('ie-border');
            } else {
              var stroke = 10,
                trial = 10;
            }
            var bar = new ProgressBar.Circle(circle[0], {
              strokeWidth: stroke,
              easing: 'easeInOut',
              duration: 2500,
              color: '#f01a3a',
              trailColor: '#DFE2E5',
              trailWidth: trial,
              svgStyle: null
            });

            numCount = data.attr('data-count');

            // Divide percentage/fractions
            if (data.hasClass('percent')) {
              if (numCount > 95) {
                // If higher than 95 take a bit more off to show bigger gap
                var num = numCount / 100 - 0.03;
              } else {
                var num = numCount / 100;
              }
            }
            if (data.hasClass('fraction')) {
              var num = numCount / 10;
            }
            if (data.hasClass('large')) {
              if (numCount < 100) {
                // If under 100 divide by 100 to get correct number, else just show it full (1.0)
                var num = numCount / 100;
              } else {
                var num = 1.0;
              }
            }

            bar.animate(num); // Number from 0.0 to 1.0
            circle.addClass('active'); // animate color

            waypoints[0].destroy(); //stop
          });
        },
        {
          offset: '70%'
        }
      );
    }
  };
  // -------------------

  // Navigation
  var navigation = function() {
    var menu = $('.mobile-menu'),
      nav = $('.primary-main');
    (navItems = $('.primary-main .nav')), (info = $('header.primary .info'));

    menu.on('click', function() {
      nav.toggleClass('active');
      navItems.toggleClass('active');
      info.toggleClass('active');
    });

    // Nav Sub Menu
    var dropdown = $('.menu-item.menu-item-has-children');
    var subDropdown = $('.submenu-item.submenu-item-has-children');

    // \Dropdown
    if (window.matchMedia('(min-width: 992px)').matches) {
      var subPersonaDrop ='';
      subDropdown.on('mouseenter', function(e) {
        var classList = $(this).attr('class').split(/\s+/);
        var not_class = ['active','submenu-item','submenu-item-has-children'];
        var idSelector ='';
        for (var i = 0; i < classList.length; i++) {
          if (not_class.indexOf(classList[i]) == -1) {
            idSelector = classList[i];
          }
        }
        subPersonaDrop = $('#'+idSelector);
        if (!sessionStorage.getItem('medical-calloutbanner')) {
          subPersonaDrop.addClass('callout-visible');
        } else {
          subPersonaDrop.removeClass('callout-visible');
        }

        $('.right').removeClass('active');
        $('.submenu-item-has-children').removeClass('active');

        subPersonaDrop.addClass('active');
        $(this).addClass('active');
        // alert(idSelector);
        subPersonaDrop.on('mouseleave', function() {
          if (subPersonaDrop.hasClass('active')) {
            subPersonaDrop.removeClass('active');
            subDropdown.removeClass('active');
          }
        });
      });
      $('.submenu-item a').on('mouseenter', function() {
        var classList = $(this).parent().attr('class').split(/\s+/);
        var not_class = ['active','submenu-item','submenu-item-has-children'];
        var idSelector ='';
        for (var i = 0; i < classList.length; i++) {
          if (not_class.indexOf(classList[i]) == -1) {
            idSelector = classList[i];
          }
        }

        if(!$(this).parent().hasClass('submenu-item-has-children')) {
            $('.right').removeClass('active');
        }
      });
      $('.menu-item a').on('mouseenter', function() {
        var classList = $(this).parent().attr('class').split(/\s+/);
        var not_class = ['active','menu-item','menu-item-has-children'];
        var idSelector ='';
        for (var i = 0; i < classList.length; i++) {
          if (not_class.indexOf(classList[i]) == -1) {
            idSelector = classList[i];
          }
        }
          if(!$(this).parent().hasClass('menu-item-has-children')) {
              $('.left').removeClass('active');
            }
      });
      dropdown.on('mouseenter', function(e) {
        var classList = $(this).attr('class').split(/\s+/);
        var not_class = ['active','menu-item','menu-item-has-children'];
        var idSelector ='';
        for (var i = 0; i < classList.length; i++) {
          if (not_class.indexOf(classList[i]) == -1) {
            idSelector = classList[i];
          }
        }
        personaDrop = $('#'+idSelector);
        if (!sessionStorage.getItem('medical-calloutbanner')) {
          personaDrop.addClass('callout-visible');
        } else {
          personaDrop.removeClass('callout-visible');
        }

        $('.persona-dropdown').removeClass('active');
        $('.menu-item-has-children').removeClass('active');
        personaDrop.addClass('active');
        $(this).addClass('active');

        personaDrop.on('mouseleave', function() {
          if (personaDrop.hasClass('active')) {
            if(!subDropdown.hasClass('active')){
              personaDrop.removeClass('active');
              dropdown.removeClass('active');
            }
          }
        });
      });



    } else {
      var dropMobile = dropdown.find('a:first').addClass('drop-mobile');

      // Mobile Dropdown
      dropMobile.on('click', function(e) {
        e.preventDefault();

        $(this).toggleClass('active');
        $(this)
          .next('.sub-menu')
          .fadeToggle();
      });
    }
  };
  $('.wrap').on('mouseenter',function (){

    var personaDrop = $('.persona-dropdown');
      if (personaDrop.hasClass('active')) {
        personaDrop.removeClass('active');
        // dropdown.removeClass('active');
      }
  });
  // -------------------

  // Navigation
  var heroPersonaSelect = function() {
    // Select
    var select = $('.hero-persona-select');

    if (select.length) {
      // IE 11 fix
      if (
        Object.hasOwnProperty.call(window, 'ActiveXObject') &&
        !window.ActiveXObject
      ) {
        $('.persona-select').css({
          opacity: 1,
          transform: 'translateY(0)'
        });
      }

      // Select2 options
      select.select2({
        placeholder: 'Select',
        minimumResultsForSearch: Infinity,
        theme: 'chase',
        width: 'style',
        dropdownPosition: 'below'
      });

      // Custom Scroll Bar
      select.on('select2:open', function(e) {
        //  bind niceScroll to dropdowns
        $('.select2-results__options').niceScroll({
          cursorborder: 'none', // optional
          background: 'rgba(255, 255, 255, 1)',
          cursorcolor: '#f01a3a',
          railoffset: { top: 0, left: -30 }
        });
        $('.homepage-slider').slick('slickPause');
      });

      select.on('select2:close', function (e) {
        $('.homepage-slider').slick('slickPlay');
      });

      select.on('select2:closing', function() {
        $('.select2-results__options')
          .getNiceScroll()
          .remove();
      });

      // On Change go to link
      select.on('change', function() {
        var link = $('option:selected', this).attr('data-link');

        location.href = link;
      });
    }
  };
  // -------------------

  var blockService = function() {
    var service = $('.service-selection');

    if (service.length) {
      var list = $('.selection-list .list'),
        items = $('.selection-list .list .list-item');

      items.on('click', function() {
        // Add/Remove Active Link on list items
        items.each(function() {
          $(this).removeClass('active');
        });
        $(this).addClass('active');

        // Show content on matching id
        var content = $('.selection-content .content'),
          data = $(this).attr('data-content');

        content.each(function() {
          var id = $(this).attr('id');

          // If id/data matches then show
          if (data == id) {
            $(this)
              .delay(500)
              .fadeIn(400, function() {
                $(this).addClass('active');
              });

            // else hide
          } else {
            $(this).fadeOut(400, function() {
              $(this).removeClass('active');
            });
          }
        });
      });
    }
  };
  // -------------------

  var slickEvents = function() {
    var eventsSlider = $('.events-slider'),
      eventsCount = $('.events-wrap .events-count');

    if (eventsSlider.length) {
      // get slider count
      eventsSlider.on('init reInit afterChange', function(
        event,
        slick,
        currentSlide,
        nextSlide
      ) {
        var i = (currentSlide ? currentSlide : 0) + 1;
        eventsCount.find('.current').text(minTwoDigits(i));
        eventsCount.find('.total').text('/' + minTwoDigits(slick.slideCount));
      });

      // Show the first slide once we get to the last one
      eventsSlider.on('beforeChange', function(
        event,
        slick,
        currentSlide,
        nextSlide
      ) {
        if (
          slick.$slides.length ==
          currentSlide + slick.options.slidesToScroll + 1
        ) {
          eventsSlider.find('.slick-slide:first').addClass('showlast');
        } else {
          eventsSlider.find('.slick-slide').each(function() {
            $(this).removeClass('showlast');
          });
        }
      });

      // Slick options
      eventsSlider.slick({
        mobileFirst: true,
        slidesToShow: 1,
        arrows: false,
        dots: true,
        fade: true,
        speed: 1000
      });

      // Arrows
      var eventsPrev = $('.events-nav .nav-prev'),
        eventsNext = $('.events-nav .nav-next');

      eventsPrev.on('click', function() {
        eventsSlider.slick('slickPrev');
      });
      eventsNext.on('click', function() {
        eventsSlider.slick('slickNext');
      });
    }
  };
  // -------------------

  var slickNews = function() {
    var newsSlider = $('.news-slider');

    if (newsSlider.length) {
      // Slick options
      newsSlider.slick({
        mobileFirst: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: false,
        speed: 1000,
        variableWidth: true,
        infinite: true
      });

      // Arrows
      var newsPrev = $('.news-nav .nav-prev'),
        newsNext = $('.news-nav .nav-next');

      newsPrev.on('click', function() {
        newsSlider.slick('slickPrev');
      });
      newsNext.on('click', function() {
        newsSlider.slick('slickNext');
      });
    }
  };
  // -------------------

  var heroVideo = function() {
    var isVideo = $('.hero.hero--video');

    if (isVideo.length) {
      var video = $('.hero-video-container'),
        play = $('.hero-video-play a'),
        iframe = $('iframe', video);

      var player = new Vimeo.Player(iframe);

      play.on('click', function() {
        player.play();
      });

      player.on('play', function() {
        isVideo.addClass('playing-video');
        video.fadeIn();
      });

      player.on('pause', function() {
        isVideo.removeClass('playing-video');
        video.fadeOut();
      });
    }
  };
  // -------------------

  var portfolios = function() {
    $(document).on('click', '.priority_selector', function (e) {
      const priority = e.target.dataset.id;
      var risk = $('#selected-risk').val();

      if (!risk) {
        risk = $('.risk_selector.active').data('id');
      }

      $('#selected-priority').val(priority);
      $('.priority_selector').removeClass('active');
      $('.priority_selector.' + priority).addClass('active');

      $('.portfolio-section__main-body-priority-item').css('display', 'none');
      $('.portfolio-section__main-body-priority-item.' + priority).css('display', 'block');
      $('.portfolio-section__item').css('display', 'none');
      $('.portfolio-section__item.risk-' + risk + '.priority-' + priority).css('display', 'block');
    });

    $(document).on('click', '.risk_selector', function (e) {
      const risk = e.target.dataset.id;
      var priority = $('#selected-priority').val();

      if (!priority) {
        priority = $('.priority_selector.active').data('id');
      }

      $('#selected-risk').val(risk);
      $('.risk_selector').removeClass('active');
      $('.risk_selector.' + risk).addClass('active');

      $('.portfolio-section__main-body-risk-item').css('display', 'none');
      $('.portfolio-section__main-body-risk-item.' + risk).css('display', 'block');
      $('.portfolio-section__item').css('display', 'none');
      $('.portfolio-section__item.risk-' + risk + '.priority-' + priority).css('display', 'block');
    });
  }
  // -------------------

  var slickPortfolio = function () {
    $('.portfolio-section__main-body__select--priorities, .portfolio-section__main-body__select--risks')
      .slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        infinite: false,
        dots: false,
        arrows: false,
        mobileFirst: true,
        centreMode: true,
        responsive: [
          {
            breakpoint: 767,
            rows: 0,
            settings: 'unslick'
          }
        ]
      });
  };
  // -------------------

  var slickTeam = function () {
    var teamSlider = $('.team--dpm__carousel');
    teamSlider.slick({
      slidesToShow: 1.2,
      slidesToScroll: 1,
      speed: 1000,
      centerMode: true,
      infinite: false,
      mobileFirst: true,
      arrows: false,
      dots: false,
      fade: false,
      centerPadding: '60px',
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2.2,
            centerMode: false
          }
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 2.2,
            centerMode: false
          }
        }
      ]
    });

    // Arrows
    const $teamPrev = $('.team--dpm__nav .nav-prev');
    const $teamNext = $('.team--dpm__nav .nav-next');

    $teamPrev.on('click', function () {
      teamSlider.slick('slickPrev');
    });

    $teamNext.on('click', function () {
      teamSlider.slick('slickNext');
    });
  };
  // -------------------

  var advisorsProfile = function() {
    var advisorList = $('.advisor-list');

    if (advisorList.length) {
      var listSecondary = $('.list-secondary li a'),
        profile = $('.advisor-selected');

      listSecondary.on('click', function() {
        // Add/remove current class
        listSecondary.each(function() {
          $(this)
            .parent()
            .removeClass('current');
        });
        $(this)
          .parent()
          .addClass('current');

        // Get Clicked on data
        var name = $(this).data('name'),
          pos = $(this).data('pos'),
          text = $(this).data('text'),
          img = $(this).data('img');

        // Get current profile data
        var profileName = $('.title', profile),
          profilePos = $('.position', profile),
          profileText = $('.copy', profile),
          profileImg = $('.profile', profile),
          profileElements = profile.find('.title, .position, .copy, .profile');

        // Animate out/In ahd change text
        profileElements
          .addClass('animate-out')
          .delay(500)
          .queue(function(next) {
            // Add classes for animating in/out
            $(this).removeClass('animate-out');
            $(this).addClass('animate-in');
            $(this).removeClass('animate-in');

            // Replace text with data attribrutes from click
            profileName.text(name);
            profilePos.text(pos);
            profileText.text(text);
            if (img) {
              profileImg.css({
                'background-image': 'url(' + img + ')',
                display: ''
              });
            } else {
              profileImg.css('display', 'none');
            }

            next();
          });
      });
    }
  };
  // -------------------

  var advisorsList = function() {
    var advisorList = $('.advisor-list');

    if (advisorList.length) {
      var listPrimary = $('.list-primary li a'),
        listCollection = $('.list-collection .list-secondary');

      listPrimary.on('click', function() {
        // Add/remove current class
        listPrimary.each(function() {
          $(this)
            .parent()
            .removeClass('current');
        });
        $(this)
          .parent()
          .addClass('current');

        // Get data-list-show and later match with data-list
        var list = $(this).data('list-show');

        listCollection.each(function() {
          // Get data-list of each list
          var collection = $(this).data('list');

          // If data list attribrutes match then show and hide others
          if (collection == list) {
            $(this, collection)
              .delay(500)
              .fadeIn(400, function() {
                $(this).addClass('active');
              });
          } else {
            $(this, collection).fadeOut(400, function() {
              $(this).removeClass('active');
            });
          }
        });
      });
    }
  };
  // -------------------

  var advisorsListMobile = function() {
    var advisorList = $('.block-advisors-mobile ');

    if (advisorList.length) {
      // Open Menu
      var dropdown = $('.advisor-dropdown'),
        lists = $('.advisor-lists');

      dropdown.on('click', function() {
        lists.slideToggle();
      });

      // Slide down advisors for each location
      var location = $('.advisor-location');

      var advisor = $('.advisors .profile'),
        profile = $('.advisor-selected');

      location.on('click', function() {
        var advisorInfo = $(this).next('.advisor-info');

        location.each(function() {
          if ($(this).hasClass('active')) {
            $(this)
              .next(advisorInfo)
              .slideUp();
          }
        });

        $(this).addClass('active');
        advisorInfo.addClass('active');
        advisorInfo.slideToggle();
        profile.hide();
      });

      // On click of each advisor change text and scroll into view
      advisor.on('click', function() {
        // Get Clicked on data
        var name = $(this).data('name'),
          pos = $(this).data('pos'),
          text = $(this).data('text'),
          img = $(this).data('img');

        // Get current profile data
        var profileName = $('.title', profile),
          profilePos = $('.position', profile),
          profileText = $('.copy', profile),
          profileImg = $('.profile', profile),
          profileElements = profile.find('.title, .position, .copy, .profile');

        $(this)
          .parent()
          .parent()
          .next(profile)
          .fadeIn(function() {
            // Animate out/In ahd change text
            profileElements.addClass('animate-out').queue(function(next) {
              // Add classes for animating in/out
              $(this).removeClass('animate-out');
              $(this).addClass('animate-in');
              $(this).removeClass('animate-in');

              // Replace text with data attribrutes from click
              profileName.text(name);
              profilePos.text(pos);
              profileText.text(text);
              profileImg.css('background-image', 'url(' + img + ')');

              next();
            });
          });
      });
    }
  };
  // -------------------

  var slickTestimonial = function() {
    var testimonialSlider = $('.testimonial-slider');

    if (testimonialSlider.length) {
      // Slick options
      testimonialSlider.slick({
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 5000
      });

      // Arrows
      var testimonialNav = $('.testimonial-nav'),
        testimonialPrev = $('.nav-prev', testimonialNav),
        testimonialNext = $('.nav-next', testimonialNav);

      testimonialNav.appendTo(testimonialSlider);

      testimonialPrev.on('click', function() {
        testimonialSlider.slick('slickPrev');
      });
      testimonialNext.on('click', function() {
        testimonialSlider.slick('slickNext');
      });
    }
  };
  // -------------------

  var videoEmbedPlay = function() {
    var embed = $('.embed-container');

    if (embed.length) {
      var iframe = $('iframe', embed),
        play = $('.embed-video-play a', embed),
        bg = $('.embed-wrap', embed);

      play.on('click', function() {
        bg.fadeOut();
        iframe[0].contentWindow.postMessage(
          '{"event":"command","func":"playVideo","args":""}',
          '*'
        );
      });
    }
  };
  // -------------------

  var arrangeATalkFormSelect = function() {
    var currentSubjects = '';
    $('.subject-wrapper .form-checkbox-wrapper ul li.active').each(function() {
      currentSubjects +=
        $(this)
          .find('span')
          .html() + ', ';
    });
    $('#subject-input').val(currentSubjects);
  };

  // -------------------
  var arrangeATalkForm = function() {
    $('.subject-wrapper .form-checkbox-wrapper ul li').on('click', function() {
      if (!$(this).hasClass('other-subject-line')) {
        $(this).toggleClass('active');
        if (
          $(this)
            .find('span')
            .html() == 'Other'
        ) {
          if ($(this).hasClass('active')) {
            $('.other-subject-line').addClass('active');
          } else {
            $('.other-subject-line').removeClass('active');
          }
        }
        arrangeATalkFormSelect();
      }
    });
  };

  // -------------------
  var checkApplicationStep1 = function() {
    const location = $('.location-confirmation .submitted-data').html().replace('-', '');
    const isLocationOffice = location.toLowerCase() === 'our office';
    const locationOffice = $('select[name="office-selector"]').val().replace(/select office/ig, '');
    const locationValidation = $('.js-address-validation');

    if (isLocationOffice) {
      locationValidation.toggle(!locationOffice);
    } else {
      locationValidation.toggle(!location);
    }

    $('.location-confirmation').addClass('show-step-1 step--submitted');

    return isLocationOffice ? (location && locationOffice) : location;
  };

  // -------------------
  var checkApplicationStep2 = function() {
    const date = $('.date-confirmation .submitted-date').html();
    const time = $('.time-confirmation .submitted-time').html();
    const dateValidation = $('#date-validation');
    const timeValidation = $('#time-validation');

    dateValidation.toggle(!date);
    timeValidation.toggle(!time);

    $('.date-confirmation, .time-confirmation').addClass('next-step-1 step--submitted');

    return date && time;
  };

  var checkApplicationStep3 = function () {
    const adviceRequests = $("input[name ='advice-requests[]']");
    const interestValidation = $('#interests-validation');

    interestValidation.toggle(!adviceRequests.is(':checked'));

    $('.service-confirmation, .personal-detail-confirmation').addClass('next-step-2 step--submitted');

    var currentSubjects = '';

    adviceRequests.each(function () {
      if ($(this).parent().hasClass('active')) {
        currentSubjects +=
            $(this)
            .closest('span')
            .text() + ', ';
      }

      $('.service-confirmation .submitted-data').html(currentSubjects);
      $("input[name='advice-requests']").val(currentSubjects);
    });

    return adviceRequests.is(':checked');
  };

  var checkApplicationStep4 = function () {
    var sections = [
      {'input:radio[name="radio-grade"]:checked': "#grade-validation"},
      {'input:radio[name="radio-advice"]:checked': "#advice-validation"},
      {'input:radio[name="radio-bma-member"]:checked': '#bma-member-validation'},
      {'input:radio[name="radio-hear"]:checked': '#where-did-you-hear-about-us-validation'},
      {'input#personal-dob': "#dob-validation"},
      {'input[name="personal-firstname"]': "#first-name-validation"},
      {'input[name="personal-lastname"]': "#last-name-validation"},
      {'input[name="personal-email"]': "#email-validation"},
      {'input[name="terms"]:checked': "#terms-validation"},
      {'input[name="personal-number"]': '#contact-number-validation'},
    ];

    if ($('.address-wrapper').is(':visible')) {
      sections.push(
        {'input[name="home-addr-line1"]': "#address-line-1-validation"},
        {'input[name="home-city"]': "#address-city-validation"},
        {'input[name="home-postcode"]': "#address-postcode-validation"}
      );
    }

    if ($('.postcode-wrapper').is(':visible')) {
      sections.push(
        {'input[name="personal-postcode"]': "#postcode-validation"}
      );
    }

    return checkApplicationStep(sections);
  };

  var checkApplicationStep = function (sections) {
    return sections.map(function (section) {
      var key = Object.keys(section)[0];
      var value = Object.keys(section).map(function(index) {
        return section[index];
      })[0];

      const $inputValue = $(key).val();
      const $validation = $(value);

      if (key.includes('personal-dob')) {
        $validation.toggle($inputValue.length !== 8);
      } else {
        $validation.toggle(!$inputValue);
      }

      return $inputValue;
    }).reduce(function(carry, value) {
      return value ? true : carry;
    });
  };

  // -------------------
  var showNextApplicationStep = function(step) {
    $('.steps-wrap .active').removeClass('active');
    $('.step-1-col').toggle(step === 1);
    $('.step-2-col').toggle(step === 2);
    $('.step-3-col').toggle(step === 3);
    $('.step-4-col').toggle(step === 4);

    var stepIndex = step - 1;
    $('.steps-wrap .row .col-12')
      .eq(stepIndex)
      .addClass('active');
  };

  // -------------------

  var setLocationOffice = function() {
    $('.address-wrap .office-helper').addClass('active');
    $('.address-wrap .work-helper').removeClass('active');
    $('.address-wrap .home-helper').removeClass('active');
    $('.location-confirmation .submitted-data').text('Our Office');
    $('#location-target').hide();
    $('.address-wrapper').hide();
    $('.office-selector').show();
    $('.postcode-wrapper').show();
  };

  var setLocationHome = function() {
    $('.location-confirmation .submitted-data').text('Your Home');
    $('.address-wrap .office-helper').removeClass('active');
    $('.address-wrap .work-helper').removeClass('active');
    $('.address-wrap .home-helper').addClass('active');
    $('#location-target')
      .text('Please enter your home address')
      .show();
    $('.address-wrapper')
      .find('input')
      .attr('disabled', false);
    $('.office-selector').hide();
    $('.address-wrapper').show();
    $('select[name=office-selector]').val('Select Office');
    $('.postcode-wrapper').hide();

    clearInputs('');
  };

  var setLocationWork = function() {
    $('.address-wrap .office-helper').removeClass('active');
    $('.address-wrap .work-helper').addClass('active');
    $('.address-wrap .home-helper').removeClass('active');
    $('.location-confirmation .submitted-data').text('Your Work');
    $('#location-target')
      .text('Please enter your work address')
      .show();
    $('.address-wrapper')
      .find('input')
      .attr('disabled', false);
    $('.address-wrapper').show();
    $('.office-selector').hide();
    $('.postcode-wrapper').hide();
  };

  var clearInputs = function(input) {
    $("input[name='home-addr-line1']").val(input);
    $("input[name='home-addr-line2']").val(input);
    $("input[name='home-city']").val(input);
    $("input[name='home-county']").val(input);
    $("input[name='home-postcode']").val(input);
  };
  // -------------------

  var formatLocation = function(loc) {
    var str = '';

    if (loc == 'Your Work' || loc == 'Your Home') {
      str = $('input[name="home-addr-line1"]').val();
      if ($('input[name="home-addr-line2"]').val() != '') {
        str += ', ' + $('input[name="home-addr-line2"]').val();
      }
      str += ', ' + $('input[name="home-addr-line2"]').val();
      str += ', ' + $('input[name="home-city"]').val();
      str += ', ' + $('input[name="home-postcode"]').val();
    } else {
      str =
        'Chase de Vere Office in: ' + $('select[name="office-selector"]').val();
    }
    return str;
  };

  var formSerialize = function() {
    $('#location-output').val(
      formatLocation($('.location-confirmation .submitted-data').html())
    );

    var data = {
      date: $('.date-confirmation .submitted-date').html().replace('-', ''),
      time: $('.time-confirmation .submitted-time').html().replace(/^ -/, ''),
      location: $('.location-confirmation .submitted-data').html(),
      address: $('input[name="home-addr-line1"]').val(),
      city: $('input[name="home-city"]').val(),
      postcode: $('input[name="home-postcode"]').val(),
      office: $('[name="office-selector"]').val()
    };
    return btoa(JSON.stringify(data));
  };

  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : sParameterName[1];
      }
    }
  };

  // -------------------
  var compForm = function() {
    var form = $('.comp-sidebar');

    if (form.length) {
      var question = $('.question', form),
        answer = $('.form-answer input', form);

      question.on('input', function() {
        if (this.value.length > 1) {
          answer.val(this.value);
        }
      });
    }
  };

  // -------------------
  var serviceListMobile = function() {
    var serviceList = $('.service-list-mobile');

    if (serviceList.length) {
      serviceList.on('click', function() {
        $('.services-list').slideToggle();
        $(this).toggleClass('active');
      });
    }
  };

  // -------------------
  var assignURLParameters = function() {
    if (getUrlParameter('utm_source') != undefined) {
      $('#campaign-source').val(getUrlParameter('utm_source'));
    }
    if (getUrlParameter('utm_medium') != undefined) {
      $('#campaign-medium').val(getUrlParameter('utm_medium'));
    }
    if (getUrlParameter('utm_campaign') != undefined) {
      $('#campaign-name').val(getUrlParameter('utm_campaign'));
    }
    if (getUrlParameter('utm_term') != undefined) {
      $('#campaign-term').val(getUrlParameter('utm_term'));
    }
    if (getUrlParameter('utm_content') != undefined) {
      $('#campaign-content').val(getUrlParameter('utm_content'));
    }
  };

  var fixSidebar = function() {
    $('ul.services-list')
      .removeClass('bottom')
      .addClass('fixed');
  };
  var fixToBottom = function() {
    $('ul.services-list')
      .addClass('bottom')
      .removeClass('fixed');
  };

  var stickySidebar = function() {
    var servicesSidebarPosition = $('ul.services-list').offset().top;
    var headerHeight = $('header.primary').outerHeight();
    var listHeight = $('ul.services-list').outerHeight();
    var containerHeight = $('.services-container').height();
    var containerStartPosition = $('.services-container').offset().top;

    $(document).on('scroll', function() {
      var cookieNotice = 0;
      var headerCallout = 0;

      if($('.cookie-notice').is(":visible"))
      {
        cookieNotice = $('.cookie-notice').outerHeight();
      }
      if($('.header--callout').is(":visible"))
      {
        headerCallout = $('.header--callout').outerHeight();
      }

      headerHeight = $('header.primary').outerHeight() + headerCallout + cookieNotice;

      if (window.matchMedia('(min-width: 1200px)').matches) {
        var logic = (servicesSidebarPosition + containerHeight) - (listHeight + headerHeight);

        if ($(document).scrollTop() >= logic) {
          fixToBottom();
        } else if (servicesSidebarPosition <= headerHeight) {
          $('ul.services-list')
            .removeClass('bottom')
            .addClass('fixed')
            .css('top',headerHeight);
        } else if (
          $(document).scrollTop() + headerHeight >=
          servicesSidebarPosition
        ) {
          $('ul.services-list')
            .removeClass('bottom')
            .addClass('fixed')
            .css('top',headerHeight);
        } else {
          $('ul.services-list')
            .removeClass('bottom')
            .removeClass('fixed');
        }
      }
    });
  };

  // -------------------
  var eventSearchScroll = function() {
    var events = $('.gmw-results-wrapper');

    if (events.length) {
      if (window.matchMedia('(min-width: 992px)').matches) {
        var headerHeight = $('header.primary').height() + 50;

        $('html, body').animate(
          {
            scrollTop: events.offset().top - headerHeight
          },
          1500
        );
      }
    }
  };

  var setCookie = function(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  };

  var getCookie = function(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  var checkCookies = function() {
    if (getCookie('tracking_utm_source') != undefined) {
      return true;
    } else if (getCookie('tracking_utm_medium') != undefined) {
      return true;
    } else if (getCookie('tracking_utm_campaign') != undefined) {
      return true;
    } else if (getCookie('tracking_utm_term') != undefined) {
      return true;
    } else if (getCookie('tracking_utm_content') != undefined) {
      return true;
    } else {
      return false;
    }
  };

  var assignCookiesToInputs = function() {
    if (getCookie('tracking_utm_source') != undefined) {
      $('#campaign-source').val(getCookie('tracking_utm_source'));
    }
    if (getCookie('tracking_utm_medium') != undefined) {
      $('#campaign-medium').val(getCookie('tracking_utm_medium'));
    }
    if (getCookie('tracking_utm_campaign') != undefined) {
      $('#campaign-name').val(getCookie('tracking_utm_campaign'));
    }
    if (getCookie('tracking_utm_term') != undefined) {
      $('#campaign-term').val(getCookie('tracking_utm_term'));
    }
    if (getCookie('tracking_utm_content') != undefined) {
      $('#campaign-content').val(getCookie('tracking_utm_content'));
    }
  };

  var checkURLForTracking = function() {
    if (getUrlParameter('utm_source') != undefined) {
      setCookie('tracking_utm_source', getUrlParameter('utm_source'), 7);
    }
    if (getUrlParameter('utm_medium') != undefined) {
      setCookie('tracking_utm_medium', getUrlParameter('utm_medium'), 7);
    }
    if (getUrlParameter('utm_campaign') != undefined) {
      setCookie('tracking_utm_campaign', getUrlParameter('utm_campaign'), 7);
    }
    if (getUrlParameter('utm_term') != undefined) {
      setCookie('tracking_utm_term', getUrlParameter('utm_term'), 7);
    }
    if (getUrlParameter('utm_content') != undefined) {
      setCookie('tracking_utm_content', getUrlParameter('utm_content'), 7);
    }
  };
  // -------------------

  // Add to localstorage cookie check
  var cookieNotice = function() {
    var notice = $('.cookie-notice'),
      close = $('.cookie-notice .close');

    if (notice.length) {
      var noticeHeight = notice.outerHeight(),
        header = $('header.primary'),
        servicesSidebar = $('.services-sidebar');

      $(window).on('DOMContentLoaded resize', function() {
        if (!Cookies.get('cookienotice', 'true')) {
          header.css('top', noticeHeight);

          if (
            servicesSidebar.length &&
            window.matchMedia('(max-width: 1200px)').matches
          ) {
            $('body').css('padding-top', header.height() + noticeHeight);
            servicesSidebar.css('top', header.height() + noticeHeight);
          } else {
            $('body').css('padding-top', header.height() + noticeHeight);
          }
        }
      });

      if (!Cookies.get('cookienotice', 'true')) {
        notice.show();
        $('body').addClass('has-cookie-notice');
      }else{
        header.css('top', 0);
      }

      close.on('click', function() {
        Cookies.set('cookienotice', 'true', { expires: 30 });
        notice.hide();

        header.css('top', 0);

        if (
          servicesSidebar.length &&
          window.matchMedia('(max-width: 1200px)').matches
        ) {
          $('body').attr('style', '');
          servicesSidebar.attr('style', '');
        } else {
          $('body').attr('style', '');
        }
      });
    }
    var cookieNotice = $('.cookie-notice').outerHeight();
    $('.cookie-notice .close').on('click', function (){
      var menuDrop =  $('.persona-dropdown').css('top');
      var newTop = menuDrop.slice(0,-2) - cookieNotice;
      $('.persona-dropdown').css('top',newTop);
    });
    if(!$('.cookie-notice').is(":visible"))
    {
      var menuDrop =  $('.persona-dropdown').css('top');
      var newTop = menuDrop.slice(0,-2) - cookieNotice;
      $('.persona-dropdown').css('top',newTop);
    }
  };

  var slickCallout = function () {
    var $calloutSlider = $('.header--callout');
    var $calloutClose = $('.header--callout__close');
    var $wrap = $('.wrap');

    if (!sessionStorage.getItem('medical-calloutbanner') && $('.header--callout').length) {
      $calloutSlider.css('display', 'block');
      $wrap.addClass('callout-open');
    }

    $calloutClose.on('click', function () {
      sessionStorage.setItem('medical-calloutbanner', false);
      $calloutSlider.remove();
      $wrap.removeClass('callout-open');
      $('.persona-dropdown').removeClass('callout-visible');
    });

    $calloutSlider.on('init', function () {
      // display: block must be set first for Slick width JS, then switched to flex on Slick init.
      if ($calloutSlider.css('display') === 'block') {
        $calloutSlider.css('display', 'flex');
      }
    });

    $calloutSlider.slick({
      autoplay: true,
      infinite: true,
      autoplaySpeed: 4000,
      mobileFirst: true,
      slidesToShow: 1,
      arrows: false,
      dots: true,
      fade: true,
      speed: 1000,
      rows: 0,
      slide: 'div',
      pauseOnHover: false,
      pauseOnFocus: false
    });
  };

  var holidays = [];

  function getHolidaysAjax() {
    $.ajax({
      url: '/wp-admin/admin-ajax.php?action=getHolidayDates',
      success: function(data) {
        data = JSON.parse(data);
        holidays = data.dates;

        $('#date-selector').datepicker({
          beforeShowDay: disableDates,
          firstDay: 1,
          minDate: 14,
          onSelect: function(date) {
            const sanitizedDate = new Date(date);
            const $slider = $('.noUi-target');
            const $jsSliderMessage = $('.js-slider-message');

            if (sanitizedDate.getDay() === 5) {
              $slider.addClass('friday-selector');
              loadSlider(true);
            } else {
              $slider.removeClass('friday-selector');
              loadSlider(false);
            }

            $jsSliderMessage.toggle($slider.hasClass('friday-selector'));
            $('.date-time-placeholder').html('');

            const formattedDate = moment(date).format('Do MMM YYYY');
            const timeField = $('.submitted-time');

            $('#dateinput').val(formattedDate);

            var $submittedDate = $('.date-confirmation');

            $submittedDate.find('.submitted-date').addClass('active')
              .text(
                  (timeField.text().length || timeField.hasClass('active'))
                    ? formattedDate + ($submittedDate.find('.submitted-time').text().indexOf('-') !== 1 ? ' - ' : '')
                    : formattedDate
              );

            formSerialize();
          }
        });
      }
    });
  }



  var loadSlider = function (friday) {
    const slider = document.getElementById('time-range');

    if (!slider.noUiSlider) {
      noUiSlider.create(slider, {
        start: [11, 13],
        step: 1,
        margin: 2, // this is what adds the minimum time allowed for booking
        behaviour: 'drag-fixed',
        connect: true,
        range: {
          'min': 8,
          'max': 20
        }
      });
      $('.timeoutput-view').text('11:00 - 13:00');
    }

    if (friday) {
      if (slider.noUiSlider.get()[0] === '18.00') {
        const fridayMax = '16:00 - 18:00';
        $('.timeoutput-view').text(fridayMax);
        $('.submitted-time').text(fridayMax);
        $('#timeoutput').val(fridayMax);
        $('html, body').animate({ scrollTop: 0 }, 'slow');

        slider.noUiSlider.updateOptions({
          start: [16, 18],
        });
      }

      slider.noUiSlider.updateOptions({
        range: {
          'min': 8,
          'max': 18
        },
      });
    } else {
      slider.noUiSlider.updateOptions({
        range: {
          'min': 8,
          'max': 20
        },
      });
    }

    slider.noUiSlider.on('slide', function () {
      const $times = slider.noUiSlider.get()[0].replace('.', ':') + ' - ' + slider.noUiSlider.get()[1].replace('.', ':');
      const dateField = $('.submitted-date');
      $('.date-time-placeholder').html('');

      $('#timeoutput').val($times);
      $('.timeoutput-view').text($times);
      $('.time-confirmation')
      .find('.submitted-time')
      .addClass('active')
      .text(
          (dateField.text().length || dateField.hasClass('active'))
              ? (dateField.text().search(/- $/) === -1 ? ' - ' : '') + $times
              : $times
      );
      formSerialize();
    });
  };

  function disableDates(date) {
    var dt = $.datepicker.formatDate('mm-dd-yy', date);
    var noWeekend = jQuery.datepicker.noWeekends(date);

     if (noWeekend[0]) {
       return $.inArray(dt, holidays) < 0 ? [true] : [false];
     }

     return noWeekend;
  }

  var arrangeConsultationLoad = function() {
    getHolidaysAjax();

    if (checkCookies() == true) {
      assignCookiesToInputs();
    } else {
      assignURLParameters();
    }

    $('input').on('change', function() {
      formSerialize();
    });
    document.addEventListener(
      'wpcf7mailsent',
      function(event) {
        location =
          $('#redirect-url').attr('data-url') + '?data=' + formSerialize();
      },
      false
    );

    $('#date-selector .ui-state-default.ui-state-active').click();

    loadSlider(false);

    $('input[type=radio][name=radio-location]').change(function() {
      switch (this.value) {
        case 'Our Office':
          setLocationOffice();
          clearInputs('N/A');
          break;
        case 'Work':
          setLocationWork();
          clearInputs('');
          break;
        case 'Home':
          setLocationHome();
          clearInputs('');
          break;
      }
    });

    $(document).on(
      'click touchstart touchend',
      '.form-checkbox-wrapper ul li',
      function() {
        $(this).toggleClass('active');
        var currentSubjects = '';
        $('.form-checkbox-wrapper ul li.active').each(function() {
          currentSubjects +=
            $(this)
              .find('span')
              .html() + ', ';
        });
        $('#advice-requests').val(currentSubjects);
        formSerialize();
      }
    );

    const $dateOfBirthDayInput = $('input#personal-dob-day');
    const $dateOfBirthMonthInput = $('input#personal-dob-month');
    const $dateOfBirthYearInput = $('input#personal-dob-year');
    const $dateOfBirthInput = $('input#personal-dob');

    $dateOfBirthDayInput
      .on('keyup', function () {
        if ($(this).val().length === 2) {
          $dateOfBirthMonthInput.focus();
        }
      })
      .on('change', function () {
        $dateOfBirthInput.val($dateOfBirthDayInput.val() + $dateOfBirthMonthInput.val() + $dateOfBirthYearInput.val());
      });

    $dateOfBirthMonthInput
      .on('keyup', function () {
        if ($(this).val().length === 2) {
          $dateOfBirthYearInput.focus();
        }
      })
      .on('change', function () {
        $dateOfBirthInput.val($dateOfBirthDayInput.val() + $dateOfBirthMonthInput.val() + $dateOfBirthYearInput.val());
      });

    $dateOfBirthYearInput
      .on('keyup', function () {
        if ($(this).val().length === 4) {
          $("input[name='personal-email']").focus();
        }
      })
      .on('change', function () {
        $dateOfBirthInput.val($dateOfBirthDayInput.val() + $dateOfBirthMonthInput.val() + $dateOfBirthYearInput.val());
      });;

    var stepCount = $('.js-step-count');
    var contactSection = $('.contact-section');

    $('.location-confirmation').on('click', function () {
      $('.consultation__summary .row').removeClass('step-1 step-2 step-3');
      $('html, body').animate({ scrollTop: 0 }, 'slow');
      stepCount.html('Step 1/4');
      contactSection.show();
      showNextApplicationStep(1);
    });

    $('.next-step-1, .date-confirmation, .time-confirmation').on('click', function () {
      if (checkApplicationStep1()) {
        $('.consultation__summary .row').addClass('step-1').removeClass('step-2 step-3');
        $('html, body').animate({ scrollTop: 0 }, 'slow');
        stepCount.html('Step 2/4');
        contactSection.hide();
        setDefaultDate();
        showNextApplicationStep(2);
      }
    });

    $('.next-step-2, .service-confirmation').on('click', function () {
      if (checkApplicationStep2()) {
        $('.consultation__summary .row').addClass('step-2').removeClass('step-1 step-3');
        $('html, body').animate({ scrollTop: 0 }, 'slow');
        stepCount.html('Step 3/4');
        contactSection.hide();
        showNextApplicationStep(3);
      }
    });

    $('.next-step-3, .personal-detail-confirmation').on('click', function () {
      if (checkApplicationStep3()) {
        $('.consultation__summary .row').addClass('step-3').removeClass('step-1 step-2');
        $('html, body').animate({ scrollTop: 0 }, 'slow');
        stepCount.html('Step 4/4');
        contactSection.hide();
        showNextApplicationStep(4);
      }
    });

    $('#consultation__form-submit').on('click', function (e) {
      e.preventDefault();
      if (checkApplicationStep4()) {
        $(this).submit();
      }
    });
  };

  var setDefaultDate = function () {
    const defaultTime = '11:00 - 13:00';
    const formattedDate = moment($('#date-selector').datepicker('getDate')).format('Do MMM YYYY');

    $('.date-time-placeholder').hide();
    $('.submitted-date').text(formattedDate + ' - ');
    $('#dateinput').val(formattedDate);
    $('.submitted-time').text(defaultTime);
    $('#timeoutput').val(defaultTime);
  };

  var toggleCheckboxState = function () {
    const adviceRequest = $("input[name ='advice-requests[]']");
    adviceRequest.change(function () {
      $(this).parent().toggleClass('active');
    });

    $("input[name='select-all[]']").change(function () {
      adviceRequest.prop('checked', this.checked).parent().toggleClass('active', this.checked);
    });

    const gradeRadio = $("input[name='radio-grade']");
    const hearAboutUsRadio = $("input[name='radio-hear']");

    gradeRadio.click(function () {
      gradeRadio.each(function () {
        $(this).parent().removeClass('active');
      });

      if ($(this).is(':checked')) {
        $(this).parent().addClass('active');
      }
    });

    hearAboutUsRadio.click(function () {
      hearAboutUsRadio.each(function () {
        $(this).parent().removeClass('active');
      });

      if ($(this).is(':checked')) {
        $(this).parent().addClass('active');
      }
    });
  };

  var accordions = function () {
    var accordion = $('.accordion-item');
    accordion.on('click', function(e) {
      e.preventDefault();
      var siblings = $(this).parent().find(accordion);

      $(this).stop().toggleClass('active');
      $(this).find('.content').slideToggle();
    });
  };

  var slickHome = function () {
    var homepageBannerSlider = $('.homepage-slider'),
      homepagePrev = $('.homepage-nav .nav-prev'),
      homepageNext = $('.homepage-nav .nav-next');

    homepageBannerSlider.slick({
      autoplay: true,
      autoplaySpeed: 4000,
      mobileFirst: true,
      slidesToShow: 1,
      arrows: false,
      dots: true,
      fade: true,
      speed: 1000,
      rows: 0,
      pauseOnHover: false,
      pauseOnFocus: false,
      responsive: [{
        breakpoint: 767,
        settings: {
          swipe: false
        }
      }]
    });

    homepageBannerSlider.addClass('button-gap');

    homepageBannerSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      var selector = $('.below-hero');
      if($(window).width() < 768) {
        if(nextSlide !== 0) {
          homepageBannerSlider.addClass('button-gap');
          if(!selector.hasClass('d-none')) {
            selector.addClass('d-none');
          }
        } else {
          homepageBannerSlider.removeClass('button-gap');
          if(selector.hasClass('d-none')) {
            selector.removeClass('d-none');
          }
        }
      }
    });

    homepagePrev.on('click', function () {
      homepageBannerSlider.slick('slickPrev');
    });

    homepageNext.on('click', function () {
      homepageBannerSlider.slick('slickNext');
    });
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function() {
        // JavaScript to be fired on all pages
        heroBgLoad();
        navigation();
        blockService();
        slickEvents();
        slickNews();
        heroVideo();
        aos();
        countUp();
        advisorsProfile();
        advisorsList();
        slickTestimonial();
        videoEmbedPlay();
        serviceListMobile();
        advisorsListMobile();
        checkURLForTracking();
        cookieNotice();
        accordions();
        copyToClipboard();
        portfolios();
        slickPortfolio();
        slickTeam();
        slickCallout();
        toggleCheckboxState();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    home: {
      init: function() {
        slickHome();
        heroPersonaSelect();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    about_us: {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    single_events: {
      init: function() {
        compForm();
      }
    },
    page_template_events_search: {
      init: function() {
        eventSearchScroll();
      }
    },
    page_template_cdv_services_inner_child: {
      init: function() {
        stickySidebar();
        var cookieNotice = $('.cookie-notice').outerHeight();
        var headerCallout = $('.header--callout').outerHeight();
        $('.cookie-notice .close').on('click', function (){
          var sidebarTop =  $('ul.services-list').css('top');
          var newTop = sidebarTop.slice(0,-2) - cookieNotice;
          $('ul.services-list').css('top',newTop);
        });
        $('.header--callout__close').on('click', function (){
          var sidebarTop =  $('ul.services-list').css('top');
          var newTop = sidebarTop.slice(0,-2) - headerCallout;
          $('ul.services-list').css('top',newTop);
        });
      }
    },
    page_template_cdv_services_index: {
      init: function() {
        stickySidebar();
        var cookieNotice = $('.cookie-notice').outerHeight();
        var headerCallout = $('.header--callout').outerHeight();
        $('.cookie-notice .close').on('click', function (){
          var sidebarTop =  $('ul.services-list').css('top');
          var newTop = sidebarTop.slice(0,-2) - cookieNotice;
          $('ul.services-list').css('top',newTop);
        });
        $('.header--callout__close').on('click', function (){
          var sidebarTop =  $('ul.services-list').css('top');
          var newTop = sidebarTop.slice(0,-2) - headerCallout;
          $('ul.services-list').css('top',newTop);
        });

      }
    },
    page_template_cdv_arrangetalk: {
      init: function() {
        $('.form-checkbox-wrapper ul li').on('click', function() {
          $(this).toggleClass('active');
          var currentSubjects = '';
          $('.form-checkbox-wrapper ul li.active').each(function() {
            currentSubjects +=
              $(this)
                .find('span')
                .html() + ', ';
          });
          $('#subject-input').val(currentSubjects);
        });

        document.addEventListener(
          'wpcf7mailsent',
          function(event) {
            location = $('#redirect-url').attr('data-url');
          },
          false
        );
      }
    },
    page_template_cdv_nonmember: {
      init: function() {
        arrangeConsultationLoad();
      }
    },
    page_template_cdv_thankyou_appointment: {
      init: function() {
        var data = JSON.parse(atob(getUrlParameter('data')));

        $('#date').text(data.date);
        $('#time').text(data.time);
        $('#location').text(data.location);
        $('#address_1').text(data.address);
        $('#city').text(data.city);
        $('#postcode').text(data.postcode);
        $('#office').text(data.office);

        if (data.address != 'N/A') {
          $('#office-wrapper').hide();
        } else {
          $('#addr-wrapper').hide();
          $('#city-wrapper').hide();
          $('#post-wrapper').hide();
        }
      }
    },
    page_template_cdv_arrangeconsultation: {
      init: function() {
        arrangeConsultationLoad();
      }
    }
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  $(document).ready(UTIL.loadEvents);


  var video = $("#tax-video");
  var large_overlay = $(".large-video-overlay");
  var large_video = large_overlay.find('video');
  large_video = large_video[0];
  video = video[0];

  if(typeof video !== 'undefined') {
    video.ontimeupdate = function () {
      percent = (video.currentTime / video.duration) * 100;
      percent = Math.floor(percent);
      seek_bar.val(percent);
    }

    $(video).on('click', function () {
      video.pause();
      large_overlay.addClass('active');
      large_video.currentTime = video.currentTime;
      large_video.play();
    });

    large_overlay.find('.close').on('click', function () {
      large_video.pause();
      video.currentTime = large_video.currentTime;
      large_overlay.removeClass('active');
      video.play();
    });
  }

  var $steps = $('.step');
  var $guide_steps = $('.next-step');
  $guide_steps.on('click', next_step);
  var selected_inputs = [];
  var email_send = $('.email-me-a-copy');
  var email_open = $('#email-open');
  var pdf_location = '';

  function next_step(e) {
    if (this.tagName === 'A') e.preventDefault();
    if (this.tagName === 'LABEL') selected_inputs.push(this.control);
    var $this = $(this);
    var $this_step = $this.closest('.step');
    var $next_step = $this_step.next('.step');
    var movement = $this_step.index() + 1;
    $steps.removeClass('active');
    $steps.css('transform', 'translateX(' + movement * -100 + '%)');
    $next_step.addClass('active');

    if ($next_step.data('finished') == true) calculate_guide_link($next_step);
  }

  function calculate_guide_link($step) {
    var data = $step.data('guideinfo');
    var selected = [];

    selected_inputs.map(function (elm) {
      selected.push(elm.id.replace(/hospital-/g, '').replace(/-and-/g, '-').replace(/-/g, '_').toLowerCase());
    });

    pdf_location = data[selected.join('_')];
    $('.js-guide-button').attr('href', pdf_location);
  }


  email_open.on('change', function () {
    if (this.checked) {
      email_send.addClass('open');
      $(this).parent().remove();
    }
  })


  var $send_guide_form = $('#send-guide-form');
  var $email_address = $('#guide-email');
  var $marketing_option = $('#marketing-include');
  var $error_block = $('.guide-error-message');
  $send_guide_form.on('submit', function (e) {
    event.preventDefault();
    var $this = $(this);

    var data = {
      email_address: $email_address.val(),
      marketing: $marketing_option.prop('checked'),
      pdf_location: pdf_location
    }

    $.ajax({
      type: 'POST',
      url: $this.attr('action'),
      data: data,
      dataType: 'json',
      beforeSend: function () {
        $this.addClass('sending');
        $error_block.removeClass('has-error');
      },
      success: function (response) {
        $this.removeClass('sending');

        if (response.status == 500) {
          $error_block.html(response.error_message);
          $error_block.addClass('has-error');
          return;
        }

        $('.success-message').addClass('sent');
      }
    });
  })




  var $header = $('header.primary');

  $('a').on('click', function (e) {
    var $this = $(this);
    if ($this.attr('href').substring(0, 1) !== '#' || $this.attr('href').length == 1) return;
    e.preventDefault();
    var $target = $($this.attr('href'));
    $([document.documentElement, document.body]).animate({
      scrollTop: $target.offset().top - $header.outerHeight()
    }, 1000);
  })

    $("#hero-form").submit( function(e) {
        e.preventDefault();
        $('.ajax-loader').fadeIn();

        $.ajax({
            type : "post",
            dataType : "json",
            url : $(this).attr('action'),
            data : {
              action : "zurich_form",
              gmc_number : $('#gmc_number').val(),
              bma_number : $('#bma_number').val(),
              consent_box :  $('#consent_box').val()
            },
            success: function(response) {
                $('.ajax-loader').fadeOut();
                if(response.success){
                  $('.step_one').fadeOut(700).promise().done(function () {
                      $('.step_two').fadeIn(700);
                  });
                }
                else{
                    $('.theme-error').html(response.data.message)
                }
            },
            error: function(response) {
                $('.ajax-loader').fadeOut();
                $('.theme-error').html('Whoops, looks like something went wrong')
            }
        })
    });
})(jQuery); // Fully reference jQuery after this point.

var setCookie = function (key, value, expiry) {
  var date = new Date();
  date.setTime(date.getTime() + (expiry * 60 * 1000));

  document.cookie = key + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
};

var getCookie = function (key) {
  var segments = document.cookie.split(key + '=');

  return (segments.length === 2) ? segments.pop().split(';').shift() : '';
};
